import React, { useState } from 'react';
import TextField from '../Shared/TextField';
import TextArea from '../Shared/TextArea';
import GreenButton from '../Buttons/GreenButton';
import Complete from '../Shared/Complete';
import { useAuthenticatedHttpQueryTrigger } from '../../hooks/useHttp';
import { httpMethods } from '../../utils/http';

export default function Report() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const {
        trigger,
        sending,
        result,
        error,
        done,
    } = useAuthenticatedHttpQueryTrigger();

    function doSave() {
        const options = {
            method: httpMethods.POST,
            body: JSON.stringify({
                title,
                description,
            }),
            headers: { 'Content-Type': 'application/json' },
        };

        trigger('/api/reports/add', options);
    }

    const hasError = done && (error || (result?.hasError ?? true));
    const errorMessage = (result?.errors || ['Ett fel uppstod']).join(', ');

    return (
        <div className="mt-4">
            {(!done || hasError) && (
                <>
                    <TextField
                        label="Titel"
                        name="title"
                        placeholder="Ex. order-id eller liknande"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                    />
                    <div className="mb-4" />
                    <TextArea
                        rows="6"
                        label="Beskrivning av felet"
                        placeholder="En full beskrivning av felet"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    />
                    <div className="text-center mt-8 mx-auto w-60">
                        <GreenButton onClick={doSave} disabled={sending}>{sending ? 'Skickar ...' : 'Skicka'}</GreenButton>
                        {hasError && (
                            <small className="block my-4 text-red-600">{errorMessage}</small>
                        )}

                    </div>
                </>
            )}
            {done && !hasError && (
                <Complete message="Sparat!" />
            )}
        </div>
    );
}
