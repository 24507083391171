import { useContext } from 'react';
import { httpMethods } from '../utils/http';
import {
    clearToken,
    clearUser,
    setToken,
    setUser,
} from '../utils/auth';
import { clearNotifications } from '../utils/notifications';
import { AuthContext } from '../contexts/AuthContext';
import useStore from './useStore';

export default function useAuth() {
    const { state, setState } = useContext(AuthContext);
    const { clearItems } = useStore();

    function setUserDetails(user) {
        setUser(user);
        setState((prev) => ({
            ...prev,
            user,
        }));
    }

    function setPreferredUnit(defaultUnit) {
        setUser({
            ...state.user,
            defaultUnit,
        });

        setState((prev) => ({
            ...prev,
            user: {
                ...prev.user,
                defaultUnit,
            },
        }));
    }

    function logOut() {
        clearItems();

        clearNotifications();
        clearToken();
        clearUser();

        setState((prev) => ({
            ...prev,
            authenticating: false,
            authenticated: false,
            errors: [],
        }));
    }

    function setResult(result) {
        const {
            errors = ['Ett fel uppstod'],
            data = {},
        } = result || {};

        const success = errors.length === 0;
        if (success) {
            setToken(data.token);
            setUser(data.user);
        }

        setState((prev) => ({
            ...prev,
            user: data?.user ?? null,
            authenticating: false,
            authenticated: success,
            errors,
        }));
    }

    function setError() {
        setState((prev) => ({
            ...prev,
            authenticating: false,
            authenticated: false,
            errors: ['Ett fel uppstod'],
        }));
    }

    function setAuthenticated(data) {
        setToken(data.token);
        setUser(data.user);

        setState((prev) => ({
            ...prev,
            user: data.user ?? null,
            authenticating: false,
            authenticated: true,
            errors: [],
        }));
    }

    function logIn(email, password) {
        setState((prev) => ({
            ...prev,
            authenticating: true,
            authenticated: false,
            errors: [],
        }));

        const options = {
            method: httpMethods.POST,
            body: JSON.stringify({ email, password }),
            headers: { 'Content-Type': 'application/json' },
        };

        fetch('/api/users/login', options)
            .then((data) => data.json())
            .then((response) => setResult(response))
            .catch((errors) => setError(errors));
    }

    const {
        authenticating,
        authenticated,
        errors,
        user,
    } = state;

    return {
        setUserDetails,
        setPreferredUnit,
        setAuthenticated,
        authenticating,
        authenticated,
        errors,
        logOut,
        logIn,
        user,
    };
}
