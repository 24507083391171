import React, { useEffect } from 'react';
import { useHttpQueryTrigger } from '../../hooks/useHttp';
import usePolling from '../../hooks/usePolling';
import { orderStatus } from '../../utils/enums';
import { httpMethods } from '../../utils/http';
import { getRouteParameter } from '../../utils/routes';

export default function Pending({
    orderId,
    setOrderData,
}) {
    const {
        trigger,
        result,
        error,
        done,
    } = useHttpQueryTrigger();

    function poll() {
        trigger(`/api/orders/verify/${orderId}`, {
            method: httpMethods.GET,
            headers: { Authorization: `Bearer ${getRouteParameter('token')}` },
        });
    }

    usePolling(poll);

    function handleSave() {
        if (done && !error) {
            const { status = orderStatus.cancelled.value, unitLockCode = '' } = result ?? {};
            setOrderData(status, unitLockCode);
        }
    }

    useEffect(handleSave, [result]);

    return (
        <div className="text-center px-4">
            <p className="font-semibold text-gray-700 mb-2">{`Väntar på bekräftelse för din order med ordernummer: ${orderId}`}</p>
            <p className="text-gray-700">Vi väntar bara på bekräftelse från Swish, när de är klara kommer du att kunna öppna kylen</p>
        </div>
    );
}
