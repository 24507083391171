import { useContext } from 'react';
import { ModalContext } from '../contexts/ModalContext';

export default function useModal() {
    const { modal, setModal } = useContext(ModalContext);

    function openModal({ title = '', component = '', componentProps = {} }) {
        setModal((m) => ({
            ...m,
            open: true,
            title,
            component,
            componentProps,
        }));
    }

    function closeModal() {
        setModal((m) => ({
            ...m,
            open: false,
        }));
    }

    const {
        open,
        title,
        component,
        componentProps,
    } = modal;

    return {
        open,
        openModal,
        closeModal,
        title,
        component,
        componentProps,
    };
}
