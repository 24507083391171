const NOTIFICATIONS_NAMESPACE = 'NOTIFICATIONS_BOXMEAL_SHOP';

export function getNotifications() {
    const notifications = localStorage.getItem(NOTIFICATIONS_NAMESPACE) ?? null;
    return notifications === null ? [] : JSON.parse(notifications);
}

export function setNotifications(notifications) {
    localStorage.setItem(NOTIFICATIONS_NAMESPACE, JSON.stringify(notifications));
}

export function clearNotifications() {
    localStorage.removeItem(NOTIFICATIONS_NAMESPACE);
}
