import React, { useState } from 'react';
import BadgeCheckIcon from '@heroicons/react/outline/BadgeCheckIcon';
import CheckCircleIcon from '@heroicons/react/outline/CheckCircleIcon';
import XCircleIcon from '@heroicons/react/outline/XCircleIcon';
import GreenButton from '../../components/Buttons/GreenButton';
import { useAuthenticatedHttpQueryTrigger } from '../../hooks/useHttp';
import { httpMethods } from '../../utils/http';
import {
    getIsoWeek,
    toDisplayDate,
    addDaysToDate,
    addWeeksToDate,
} from '../../utils/datetime';

const weekdays = (monday) => [
    { day: 1, description: `Måndag, ${toDisplayDate(monday)}` },
    { day: 2, description: `Tisdag, ${toDisplayDate(addDaysToDate(monday, 1))}` },
    { day: 3, description: `Onsdag, ${toDisplayDate(addDaysToDate(monday, 2))}` },
    { day: 4, description: `Torsdag, ${toDisplayDate(addDaysToDate(monday, 3))}` },
    { day: 5, description: `Fredag, ${toDisplayDate(addDaysToDate(monday, 4))}` },
];

export default function SaveLunch({
    year,
    week,
    monday,
    bookedLunches,
}) {
    const [lunches, setLunches] = useState(weekdays(monday).map((w) => ({
        ...w,
        isBooked: bookedLunches.find((b) => b.day === w.day)?.isBooked ?? false,
    })));

    function toggleLunch(day) {
        const copy = [...lunches];
        const index = copy.findIndex((l) => l.day === day);

        if (index !== -1) {
            copy[index].isBooked = !copy[index].isBooked;
            setLunches(copy);
        }
    }

    const {
        trigger,
        sending,
        result,
        error,
        done,
    } = useAuthenticatedHttpQueryTrigger();

    function doSave() {
        const options = {
            method: httpMethods.POST,
            body: JSON.stringify(lunches.map((l) => ({
                day: l.day,
                isBooked: l.isBooked,
            }))),
            headers: { 'Content-Type': 'application/json' },
        };

        trigger(`/api/lunch/${year}/${week}`, options);
    }

    const hasError = done && (error || (result?.hasError ?? true));
    const errorMessage = (result?.errors || ['Ett fel uppstod']).join(', ');

    const nextWeek = getIsoWeek(addWeeksToDate(new Date(), 1));
    const isBookable = week === nextWeek;

    return (
        <>
            {(!done || hasError) && (
                <>
                    <p className="text-gray-700 text-center mb-6">För att vi enklare ska veta hur många matlådor ni behöver boka vilka dagar du vill ha mat. Tack! </p>
                    {lunches.map((l) => (
                        <div
                            key={`lunch-day-${l.day}`}
                            className={`py-2 px-4 rounded-full border-2 ${l.isBooked ? 'border-boxmeal-green-500' : 'border-gray-400'} focus:outline-none mb-4`}
                            role="button"
                            tabIndex={0}
                            onClick={() => (isBookable ? toggleLunch(l.day) : () => { })}
                        >
                            <div className="flex">
                                <div className="flex-grow">
                                    <p className="text-gray-700 mt-1">{l.description}</p>
                                </div>
                                <div className="flex-none w-8">
                                    {l.isBooked ? <CheckCircleIcon className="text-boxmeal-green-500 w-8 h-8" /> : <XCircleIcon className="text-gray-400 h-8 w-8" />}
                                </div>
                            </div>
                        </div>
                    ))}
                    {isBookable && (
                        <div className="text-center mt-8 mx-auto w-60">
                            <GreenButton onClick={doSave} disabled={sending}>{sending ? 'Sparar ...' : 'Spara'}</GreenButton>
                            {hasError && (
                                <small className="block my-4 text-red-600">{errorMessage}</small>
                            )}
                        </div>
                    )}
                </>
            )}
            {done && !hasError && (
                <>
                    <BadgeCheckIcon className="text-boxmeal-green-500 w-28 h-28 mx-auto" />
                    <p className="font-semibold text-gray-700 text-center mt-8">{`Dina luncher för vecka ${week} är nu sparade`}</p>
                </>
            )}
        </>
    );
}
