import React, { useState } from 'react';
import ChevronDownIcon from '@heroicons/react/outline/ChevronDownIcon';
import ChevronUpIcon from '@heroicons/react/outline/ChevronUpIcon';
import Item from '../../components/Products/Item';

function ItemWrapper({ products }) {
    return (
        <div className="grid grid-cols-2 gap-4 mb-4">
            {products.map((p) => (
                <Item
                    key={`product-${p.id}`}
                    product={p}
                />
            ))}
        </div>
    );
}

export default function Category({ name, products }) {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen((o) => !o);

    return (
        <>
            {products.length > 2 && (
                <>
                    <div role="button" tabIndex={0} className="border-2 border-boxmeal-green-600 rounded-xl py-2 px-4 focus:outline-none" onClick={toggleOpen}>
                        <div className="flex">
                            <div className="flex-grow">
                                <p className="text-gray-700 font-semibold text-xl mr-5">{name}</p>
                            </div>
                            <div className="flex-none pt-1">
                                {open ? <ChevronUpIcon className="h-5" /> : <ChevronDownIcon className="h-5" />}
                            </div>
                        </div>
                    </div>
                    <ItemWrapper products={open ? products : products.slice(0, 2)} />
                </>
            )}
            {products.length <= 2 && (
                <>
                    <div role="button" tabIndex={0} className="border-2 border-boxmeal-green-600 rounded-xl py-2 px-4">
                        <p className="text-gray-700 font-semibold text-xl">{name}</p>
                    </div>
                    <ItemWrapper products={products} />
                </>
            )}
        </>
    );
}
