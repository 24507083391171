import React from 'react';

export default function Info({ description }) {
    return (
        <div className="mt-4">
            <p className="text-gray-700 mb-2">Produktbeskrivning</p>
            <p className="text-gray-700 font-bold">{description}</p>
        </div>
    );
}
