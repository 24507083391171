import React from 'react';
import BadgeCheckIcon from '@heroicons/react/outline/BadgeCheckIcon';

export default function Complete({ message = '' }) {
    return (
        <div className="my-20">
            <BadgeCheckIcon className="text-boxmeal-green-500 w-28 h-28 mx-auto" />
            <p className="text-lg font-medium text-gray-700 text-center mt-8">{message}</p>
            <p className="text-gray-500 text-center mt-1">Du kan nu stänga ned rutan</p>
        </div>
    );
}
