import React, { useEffect } from 'react';
import GreenButton from '../../components/Buttons/GreenButton';
import { getToken } from '../../utils/auth';
import { allRoutes } from '../../utils/routes';

export default function Payment({
    orderId,
    token,
}) {
    const redirectUrl = `swish://paymentrequest?token=${token}&callbackurl=${window.location.origin}${allRoutes.confirmation}/${orderId}?token=${getToken().value}`;
    const openSwish = () => { window.location = redirectUrl; };

    useEffect(() => {
        const redirect = setTimeout(() => {
            window.location = redirectUrl;
        }, 1000);

        return () => clearTimeout(redirect);
    }, [redirectUrl]);

    return (
        <>
            <p className="text-gray-700 font-semibold mt-5 mb-8 text-center">Swish borde öppnas automatiskt, men om inte så kan du öppna Swish genom att trycka på knappen nedan!</p>
            <GreenButton onClick={openSwish}>Öppna Swish</GreenButton>
        </>
    );
}
