import React from 'react';
import useStore from '../../hooks/useStore';
import useModal from '../../hooks/useModal';

export default function Item({ product }) {
    const {
        items,
        addItem,
    } = useStore();

    const { openModal } = useModal();
    const amountInBag = items.filter((item) => item.id === product.id).length;

    function addItemToBag() {
        if (amountInBag < product.currentStock) {
            addItem(product);
        }
    }

    function openProductInfo() {
        openModal({
            title: `Info om ${product.article.name}`,
            component: 'productInfo',
            componentProps: { description: product.article.description },
        });
    }

    return (
        <div className="shadow-xl rounded-2xl">
            <div className="flex flex-col h-full">
                <div className="relative">
                    {amountInBag > 0 && (
                        <div className="absolute -right-2 top-2 bg-boxmeal-green-600 rounded-full h-8 w-8 flex items-center justify-center text-gray-50">
                            {amountInBag}
                        </div>
                    )}
                    <div role="button" tabIndex={0} className="rounded-t-2xl focus:outline-none" onClick={addItemToBag}>
                        <img src={product.article.imageUrl} alt={product.article.name} />
                        <div className="px-2 pb-2 text-center">
                            <p className="text-gray-700">{product.article.name}</p>
                            <p className="text-gray-700 font-bold">{`${product.article.price} :-`}</p>
                        </div>
                    </div>
                </div>
                <div className="flex-grow" />
                <button type="button" className="rounded-b-2xl p-1 text-center bg-boxmeal-green-500 w-full text-gray-50" onClick={openProductInfo}>Info</button>
            </div>
        </div>
    );
}
