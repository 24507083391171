import React from 'react';
import { useHistory } from 'react-router-dom';
import GreenButton from '../../components/Buttons/GreenButton';
import LightButton from '../../components/Buttons/LightButton';
import { allRoutes } from '../../utils/routes';

export default function Start() {
    const history = useHistory();
    return (
        <>
            <img src="/img/can.png" alt="Människa som öppnar en Coca Cola" className="rounded-b-3xl shadow-2xl mb-4" />
            <div className="p-4">
                <GreenButton onClick={() => history.push(allRoutes.login)}>Logga in</GreenButton>
                <div className="my-4" />
                <LightButton onClick={() => history.push(allRoutes.register)}>Registrera konto</LightButton>
            </div>
        </>
    );
}
