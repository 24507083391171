export function toDateTimeString(d) {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: 'Europe/Stockholm',
    };

    const datetime = new Intl.DateTimeFormat('sv-SE', options).format(d);
    const parts = datetime.split(' ');

    return `${parts[0]}T${parts[1]}`;
}

export function toDateString(d) {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'Europe/Stockholm',
    };

    return new Intl.DateTimeFormat('sv-SE', options).format(d);
}

export function toDisplayDateTime(d) {
    return toDateTimeString(d).replace('T', ' ');
}

export function toDisplayDate(d) {
    return toDateString(d).replace('T', ' ');
}

export function getIsoWeek(date) {
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
}

export function getIsoYear(date) {
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    return date.getFullYear();
}

export function getMonday(date) {
    const day = date.getDay();
    // adjust when day is sunday
    const difference = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(difference));
}

export function addDaysToDate(date, days) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}

export function addWeeksToDate(date, weeks) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + weeks * 7);
    return newDate;
}
