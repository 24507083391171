import React, { useState } from 'react';
import LogInForm from './LogInForm';
import ResetForm from './ResetForm';

const views = { LOGIN: 'LOGIN', RESET: 'RESET' };
export default function Login() {
    const [view, setView] = useState(views.LOGIN);
    const goToLogIn = () => setView(views.LOGIN);
    const goToReset = () => setView(views.RESET);

    return (
        <div className="px-4 pb-4">
            {view === views.LOGIN && <LogInForm goToReset={goToReset} />}
            {view === views.RESET && <ResetForm goToLogIn={goToLogIn} />}
        </div>
    );
}
