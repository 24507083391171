import React from 'react';

export default function LightButton({
    children,
    onClick,
    disabled = false,
}) {
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            className="border-2 border-boxmeal-green-600 text-gray-700 py-3 px-4 rounded-full focus:outline-none w-full disabled:opacity-50"
        >
            {children}
        </button>
    );
}
