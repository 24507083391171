import React, { useState } from 'react';
import GreenButton from '../Buttons/GreenButton';
import useModal from '../../hooks/useModal';
import usePolling from '../../hooks/usePolling';
import { authenticatedHttpQuery, httpMethods } from '../../utils/http';

export default function LatestOrder() {
    const [order, setOrder] = useState(null);
    const { openModal } = useModal();

    function openOrder() {
        openModal({
            title: `Order #${order.id}`,
            component: 'order',
            componentProps: { id: order.id },
        });
    }

    async function pollLatestOrder() {
        const data = await authenticatedHttpQuery({ url: '/api/orders/latest', options: { method: httpMethods.GET } });
        const { errors = [] } = data;

        if (errors.length === 0) {
            setOrder(data);
        }
    }

    usePolling(pollLatestOrder, 10 * 1000);
    if (order == null) return null;

    return (
        <div className="fixed bottom-24 w-full">
            <div className="mx-auto w-60">
                <GreenButton onClick={openOrder}>Se senaste order</GreenButton>
            </div>
        </div>
    );
}
