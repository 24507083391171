import {
    useRef,
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    httpQuery,
    parallelHttpQuery,
    authenticatedHttpQuery,
    authenticatedParallelHttpQuery,
    httpMethods,
} from '../utils/http';
import useDeepEffect from './useDeepEffect';

function useHttpQuerier(request, callback) {
    const [state, setState] = useState({
        data: {},
        error: false,
        loading: true,
    });

    const isLoading = useRef(state.loading);
    useEffect(() => { isLoading.current = state.loading; });

    useDeepEffect(() => {
        if (!isLoading.current) {
            setState((previous) => ({
                ...previous,
                loading: true,
            }));
        }

        function setResult(data) {
            setState({
                data,
                error: false,
                loading: false,
            });
        }

        function setError() {
            setState({
                error: true,
                loading: false,
            });
        }

        callback(request, setResult, setError);
    }, [request]);

    const {
        data,
        error,
        loading,
    } = state;

    const ready = !loading && !error;
    return {
        loading,
        error,
        ready,
        data,
    };
}

export function useAuthenticatedHttpQuery(url, options) {
    return useHttpQuerier({ url, options }, authenticatedHttpQuery);
}

export function useAuthenticatedParallelHttpQuery(requests) {
    return useHttpQuerier(requests, authenticatedParallelHttpQuery);
}

export function useAuthenticatedHttpGet(url) {
    return useAuthenticatedHttpQuery(url, { method: httpMethods.GET });
}

export function useAuthenticatedParallelHttpGet(requests) {
    return useAuthenticatedParallelHttpQuery(requests.map((r) => [r[0], { method: httpMethods.GET }, r[1]]));
}

export function useHttpQuery(url, options) {
    return useHttpQuerier({ url, options }, httpQuery);
}

export function useParallelHttpQuery(requests) {
    return useHttpQuerier(requests, parallelHttpQuery);
}

export function useHttpGet(url) {
    return useHttpQuery(url, { method: httpMethods.GET });
}

export function useParallelHttpGet(requests) {
    return useParallelHttpQuery(requests.map((r) => [r[0], { method: httpMethods.GET }, r[1]]));
}

const initialTriggerState = {
    sending: false,
    result: {},
    error: false,
    done: false,
};

function useHttpQueryTriggerer(callback) {
    const [state, setState] = useState(initialTriggerState);

    function setResult(data) {
        setState({
            sending: false,
            result: data,
            error: false,
            done: true,
        });
    }

    function setError() {
        setState({
            sending: false,
            error: false,
            done: true,
        });
    }

    const trigger = useCallback((url, options) => {
        setState({ ...initialTriggerState, sending: true });
        callback({ url, options }, setResult, setError);
    }, []);

    const {
        sending,
        result,
        error,
        done,
    } = state;

    return {
        trigger,
        sending,
        result,
        error,
        done,
    };
}

export function useHttpQueryTrigger() {
    return useHttpQueryTriggerer(httpQuery);
}

export function useAuthenticatedHttpQueryTrigger() {
    return useHttpQueryTriggerer(authenticatedHttpQuery);
}
