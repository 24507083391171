import React from 'react';
import { notificationStatus } from '../../utils/enums';

export default function Preview({
    message,
    status,
}) {
    return (
        <div className="relative p-2 w-full">
            {status === notificationStatus.unseen.value && (
                <div className="absolute bg-red-600 h-2 w-2 rounded-full right-2 top-2" />
            )}
            <p className={status === notificationStatus.clicked.value ? 'text-gray-500' : 'text-gray-700 font-bold'}>{message.length > 30 ? `${message.slice(0, 30)} ...` : message}</p>
        </div>
    );
}
