import React from 'react';
import LightButton from '../../components/Buttons/LightButton';
import useModal from '../../hooks/useModal';

export default function TermsOfAgreement() {
    const { closeModal } = useModal();
    return (
        <div className="mt-4">
            <p className="text-gray-700 text-3xl mb-8">Våra generella villkor</p>
            <p>
                <p className="text-xl">1. Allmänt om tjänsten</p>
                Boxmeal är en tjänst för köp av livsmedel på din arbetsplats. Boxmeal-butiken är öppen för dig dygnet runt (om inte annat avtalas parterna emellan) varje dag i veckan. Du har tillgång till ett brett och varierat sortiment av mellanmål, kalla drycker samt frukostmackor och kylda måltider vid beställning.
                Tjänsten omfattas i allmänhet av köp- och medlemsvillkoren nedan. Dessa köp-och medlemsvillkor börjar gälla från och med kontots skapande.
                <p className="text-lg">1.1 Parterna</p>
                Köp- och tjänstevillkoren reglerar avtalet mellan dig som kund (nedan: du, kunden) och Boxmeal Gävle AB (nedan: vi, Boxmeal). Tjänsten tillhandahålls i samarbete med arbetsplatsens arbetsgivare.
                <p className="text-lg">1.2 Avtalets giltighet</p>
                Köp- och medlemsvillkoren utgör parternas huvudsakliga reglering av de frågor som omfattas av dessa villkor.
                Boxmeal förbehåller sig rätten att uppdatera och revidera dessa köp- och medlemsvillkor. Därför ber vi dig alltid kontrollera gällande villkor www.boxmeal.se. Vid större förändringar av köp- och medlemsvillkoren meddelar vi kunden per e-post senast 14 dagar innan ändringarna träder i kraft.
                <p className="text-lg">1.3 Kontaktuppgifter</p>
                Tjänsten ägs och drivs av Boxmeal AB, org.nr. 559175-3685, (nedan ”Boxmeal”). Södra Kungsgatan 57, 802 55 Gävle. Har du frågor om köp- och medlemsvillkoren eller om vår integritetspolicy hur vi hanterar dina personliga uppgifter eller har ett annat
                kundtjänstärende, kontakta oss gärna på telefon: 072-510 52 42 eller 070-649 45 00 eller per e-post: info@boxmeal.se. Aktuella kontaktuppgifter till oss finns även på www.boxmeal.se
                <p className="text-xl">2. Att handla hos Boxmeal</p>
                För information om vilka personuppgifter vi behandlar och syftet med behandlingen hänvisar vi till vår integritetspolicy. Du kan också med ditt samtycke överlåta registreringen till en säljare eller annan representant för Boxmeal.
                <p className="text-lg">2.1 Uppgifternas riktighet</p>
                Uppgifterna vid betalning ska vara korrekta och du är skyldig att uppdatera dessa uppgifter om de vid någon tidpunkt skulle förändras.
                <p className="text-lg">2.2 Behandling av personuppgifter</p>
                När du köper våra produkter och använder våra tjänster kan du direkt eller indirekt komma att ge oss information om dig själv på ett antal olika sätt, såsom när du genomför ett köp via våra boxar, kontaktar oss, använder någon av våra webtjänster eller deltar i events eller tävlingar där du lämnar ifrån dig personuppgifter av något slag. Vi är naturligtvis måna om att du alltid skall känna dig trygg att vi behandlar dina personuppgifter ansvarsfullt med hänsyn till din integritet och att vi är transparenta med vilken information vi samlar in om dig, hur dessa uppgifter kan komma att användas och hur länge dina personliga uppgifter lagras hos oss.
                Dataskyddsförordningen som trädde i kraft 25 maj 2018 har stärkt dina rättigheter att styra hur du vill att vi hanterar personuppgifter kopplade till dig som är kund hos oss. Du har bland annat rätt till ett s.k. registerutdrag, få dina uppgifter rättade, ge och återkalla ditt samtycke för behandling av dina personuppgifter, avsäga dig direktreklam från oss. Du kan även motsätta dig till behandling av personuppgifter som grundar sig på berättigat intresse samt få dina uppgifter raderade när det inte längre finns legitimt skäl för fortsatt behandling av dina uppgifter. Du har även möjlighet att begränsa dina uppgifter om du anser att uppgifter som vi lagrat om dig inte är korrekta samt under vissa omständigheter kunna flytta din data eller vissa uppgifter om dig (s.k. dataportabilitet).
                För mer detaljerad information om behandling av personuppgifter, hänvisar vi dig att läsa mer om detta i vår integritetspolicy.
                <p className="text-xl">3. Betalning</p>
                Boxmeal förbehåller sig rätten att kontrollera dina personuppgifter och genomföra kreditupplysning. Om resultatet av kreditupplysningen indikerar en förhöjd kreditrisk förbehåller sig Boxmeal rätten att avslå registreringen. Boxmeal eller arbetsplatsens arbetsgivare förbehåller sig rätten avsluta/spärra konton vid missbruk eller obetalda köp.
                <p className="text-lg">3.1 Betalning av köp</p>
                Betalning av köp i våra boxar sker i direkt anslutning till att du fysiskt plockar varan ur utplacerad kyl/frys, alternativt plockar ut sedan tidigare beställda varor. Betalning via Swish, kort eller faktura. Vid betalning med ovan nämnda betalningsmetoder sker debitering automatiskt för köpta varor från ditt konto. Varor som inte betalats för förläggs med faktura till arbetsgivare. Vid utebliven betalning skickas en påminnelseavgift och därefter inkassoföreläggande. Vid hävt köp följer Boxmeal konsumentverkets rekommendationer för ångerrätt och återbetalning.
                Boxmeal åtar sig att kontrollera vid varje påfyllningstillfälle att saldot i boxen stämmer överrens med systemets saldo. Boxmeal åtar sig också att föra dialog med arbetsgivaren gällande eventuellt svinn och åtgärder för detta.
                <p className="text-lg">3.2 Betalningsansvar</p>
                Som kund finns ett personligt betalningsansvar för de köp som görs i våra boxar. Du står därmed personligt bunden till att inbetald summa står i paritet med utplockade varor ur kyl/frys.
                <p className="text-xl">4. Livsmedelsfrågor</p>
                <p className="text-lg">4.1 Matallergier</p>
                Vårt sortiment kan innehålla nötter och andra allergener. Kunden ansvarar själv för att läsa på innehållsförteckningarna. Kunder med en överkänslighet eller allergi uppmanas vara extra uppmärksamma.
                <p className="text-lg">4.2 Livsmedelsupplysning</p>
                Som kund har du fri tillgång till vår kundtjänst som svarar på dina frågor eller önskemål, mail: info@boxmeal.se.
                <p className="text-lg">4.3 Reklamationer</p>
                Boxmeal följer konsumentköpslagen och hanterar reklamationer i första hand enligt vår smakgaranti. Smakgarantin innebär att du oavsett anledning kan reklamera köpt produkt som gjorts de senaste dagarna. Smakgarantin krediterar dig för varans värde på nästkommande månadssammanställning.
                <p className="text-lg">4.4 Ångerrätt</p>
                Den lagstadgade ångerrätter gäller inte vid köp av livsmedel från Boxmeal enligt 14§ distanshandelslagen. Detta eftersom tjänsten och fullgörandet har påbörjats i samband med inköp av varorna och eftersom det ibland rör sig om färska varor som snabbt kan försämras när de blir gamla. Om du häver köpet kommer vi att återbetala dig inom 30 dagar förutsatt att vi har mottagit rätt uppgifter att betala ut beloppet. Vid en eventuell tvist har vi som policy att följa Allmänna Reklamationsnämndens rekommendationer.
                <p className="text-lg">4.5 Mat</p>
                Vid beställning av mat / mackor skall beställning ske innan kl 11.00 dag leverans.
                Vid sjukfall på bokad matlåda/matlådor maila till info@boxmeal.se
                - Endast mat från menyn är beställningsbar.
                - Tillbehör så som bestick, dryck, sallad o bröd ingår ej.
                - Vi förser endast med gluten och laktosfria alternativ till matallergier.
                <p className="text-xl">5. Övrigt</p>
                <p className="text-lg">5.1 Förändringar i tjänstens innehåll</p>
                Boxmeal äger rätt att genomföra förändringar beträffande pris och/eller tjänstens innehåll. Samtliga kunder meddelas om detta via mail eller telefon utifrån de uppgifter som lämnats vid registreringen. Boxmeal äger rätt att när som helst avsluta tjänsten och därmed möjligheten att handla i boxen. Kunden blir vid sådan händelse informerad via mail eller telefon.
                <p className="text-lg">5.2 Överlåtelse av rättigheter och skyldigheter</p>
                Boxmeal förbehåller sig rätten att överlåta sina rättigheter och skyldigheter enligt detta avtal till en annan part till exempel i samband med en förändring i företagets organisation.

            </p>
            <LightButton onClick={closeModal}>Stäng</LightButton>
        </div>
    );
}
