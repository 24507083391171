import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { allRoutes, getRouteParameter } from '../../utils/routes';
import GreenButton from '../../components/Buttons/GreenButton';
import LightButton from '../../components/Buttons/LightButton';
import TextField from '../../components/Shared/TextField';
import useAuth from '../../hooks/useAuth';
import keyCodes from '../../utils/events';

export default function LogInForm({ goToReset }) {
    const history = useHistory();

    const {
        authenticating,
        authenticated,
        errors,
        logIn,
    } = useAuth();

    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
    });

    function doLogIn() {
        logIn(credentials.email, credentials.password);
    }

    function checkKey(e) {
        if (e.key === keyCodes.enter) {
            doLogIn();
        }
    }

    function setEmail(e) {
        setCredentials((cred) => ({
            ...cred,
            email: e.target.value,
        }));
    }

    function setPassword(e) {
        setCredentials((cred) => ({
            ...cred,
            password: e.target.value,
        }));
    }

    function handleLogin() {
        if (authenticated) {
            const redirect = getRouteParameter('redirect') ?? '';
            history.push(redirect === '' ? allRoutes.home : redirect);
        }
    }

    useEffect(handleLogin, [history, authenticated]);

    return (
        <>
            <TextField
                type="email"
                value={credentials.email}
                onChange={setEmail}
                label="E-post"
                onKeyDown={checkKey}
                placeholder="din-mail@gmail.com"
                className="mb-3"
                autoComplete="username"
            />
            <div className="my-4" />
            <TextField
                type="password"
                value={credentials.password}
                onChange={setPassword}
                onKeyDown={checkKey}
                label="Lösenord"
                placeholder="Lösenord"
                autoComplete="current-password"
            />
            <div className="text-center mt-16">
                <GreenButton onClick={doLogIn} disabled={authenticating}>{authenticating ? 'Skickar ...' : 'Logga in'}</GreenButton>
                {errors.length !== 0 && (
                    <small className="block my-4 text-red-600">{errors.join(', ')}</small>
                )}
                <div className="my-4" />
                <LightButton onClick={() => history.push(allRoutes.register)} disabled={authenticating}>Registrera</LightButton>
                <button
                    type="button"
                    className="text-boxmeal-green-600 py-3 px-4 focus:outline-none w-full disabled:opacity-50 mt-8"
                    onClick={goToReset}
                    disabled={authenticating}
                >
                    Återställ lösenord
                </button>
            </div>
        </>
    );
}
