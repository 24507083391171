export const allRoutes = {
    start: '/',
    home: '/home',
    login: '/login',
    lunch: '/lunch',
    account: '/account',
    history: '/history',
    support: '/support',
    settings: '/settings',
    checkout: '/checkout',
    register: '/register',
    completeReset: '/complete-reset',
    favorites: '/favorites',
    confirmation: '/confirmation',
};

export function getRouteParameter(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
