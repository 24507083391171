import React from 'react';
import ChevronDownIcon from '@heroicons/react/outline/ChevronDownIcon';
import ChevronUpIcon from '@heroicons/react/outline/ChevronUpIcon';
import useAuth from '../../hooks/useAuth';
import useModal from '../../hooks/useModal';

export default function OpenChooseUnit() {
    const { user } = useAuth();
    const { open, openModal } = useModal();

    function openUnitChooser() {
        openModal({ title: 'Välj butik', component: 'chooseUnit' });
    }

    return (
        <div role="button" tabIndex={0} className="flex h-full justify-center items-center focus:outline-none" onClick={openUnitChooser}>
            <p className="text-gray-700 font-semibold mr-2">{user?.defaultUnit?.name ?? 'Välj butik'}</p>
            {open ? <ChevronUpIcon className="h-5" /> : <ChevronDownIcon className="h-5" />}
        </div>
    );
}
