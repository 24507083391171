import React, { createContext, useState } from 'react';
import { getItems } from '../utils/store';

const defaultItems = getItems() ?? [];
export const StoreContext = createContext(defaultItems);

export default function StoreProvider({ children }) {
    const [items, setItems] = useState(defaultItems);
    return (
        <StoreContext.Provider value={{ items, setItems }}>
            {children}
        </StoreContext.Provider>
    );
}
