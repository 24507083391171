const SHOP_NAMESPACE = 'STORE_BOXMEAL_SHOP';

export function getItems() {
    const shoppingBag = localStorage.getItem(SHOP_NAMESPACE) ?? null;
    return shoppingBag === null ? [] : JSON.parse(shoppingBag);
}

export function setItems(shoppingBag) {
    localStorage.setItem(SHOP_NAMESPACE, JSON.stringify(shoppingBag));
}

export function clearItems() {
    localStorage.removeItem(SHOP_NAMESPACE);
}
