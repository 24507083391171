import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { allRoutes } from '../../utils/routes';
import useAuth from '../../hooks/useAuth';

export default function PublicRoute({
    path,
    exact,
    component,
}) {
    const { authenticated } = useAuth();
    if (authenticated) {
        return (
            <Route
                path={path}
                exact={exact}
                render={() => <Redirect to={allRoutes.home} />}
            />

        );
    }

    return (
        <Route
            path={path}
            exact={exact}
            component={component}
        />
    );
}
