import { getToken } from './auth';

export const httpMethods = {
    GET: 'GET',
    POST: 'POST',
};

function wrapToken(options = {}) {
    const token = getToken();
    return {
        ...options,
        headers: {
            Authorization: `Bearer ${token.value}`,
            ...options.headers ?? {},
        },
    };
}

export async function httpQuery(request, success, error) {
    try {
        const { url, options = {} } = request;
        const data = await fetch(url, options);
        const response = await data.json();
        return (success ? success(response) : response);
    } catch (errors) {
        return (error ? error(errors) : { errors });
    }
}

export async function parallelHttpQuery(
    requests,
    success,
    error,
) {
    try {
        const responses = await Promise.all(requests.map((r) => {
            const [url, options] = r;
            return httpQuery({ url, options });
        }));

        const data = {};
        for (let i = 0; i < requests.length; i += 1) {
            const [, , key] = requests[i];
            data[key] = responses[i];
        }

        return (success ? success(data) : data);
    } catch (errors) {
        return (error ? error(errors) : { errors });
    }
}

export function authenticatedHttpQuery(request, success, error) {
    const { url, options = {} } = request;
    return httpQuery({ url, options: wrapToken(options) }, success, error);
}

export async function authenticatedParallelHttpQuery(
    requests,
    success,
    error,
) {
    return parallelHttpQuery(requests.map((r) => ([r[0], wrapToken(r[1]), r[2]])), success, error);
}

function getWait(wait) {
    return (typeof wait === 'function') ? wait() : wait;
}

function defer() {
    const deferred = {};
    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    return deferred;
}

export function debounce(fn, wait = 0, options = {}) {
    let lastCallAt;
    let deferred;
    let timer;
    let pendingArgs = [];

    function flush() {
        const thisDeferred = deferred;
        clearTimeout(timer);

        Promise.resolve(
            options.accumulate
                ? fn.call(this, pendingArgs)
                : fn.apply(this, pendingArgs[pendingArgs.length - 1]),
        )
            .then(thisDeferred.resolve, thisDeferred.reject);

        pendingArgs = [];
        deferred = null;
    }

    return function debounced(...args) {
        const currentWait = getWait(wait);
        const currentTime = new Date().getTime();

        const isCold = !lastCallAt || (currentTime - lastCallAt) > currentWait;

        lastCallAt = currentTime;

        if (isCold && options.leading) {
            return options.accumulate
                ? Promise.resolve(fn.call(this, [args])).then((result) => result[0])
                : Promise.resolve(fn.call(this, ...args));
        }

        if (deferred) {
            clearTimeout(timer);
        } else {
            deferred = defer();
        }

        pendingArgs.push(args);
        timer = setTimeout(flush.bind(this), currentWait);

        if (options.accumulate) {
            const argsIndex = pendingArgs.length - 1;
            return deferred.promise.then((results) => results[argsIndex]);
        }

        return deferred.promise;
    };
}
