import React from 'react';
import Modal from '../Shared/Modal';
import useAuth from '../../hooks/useAuth';
import AuthenticatedLayout from './AuthenticatedLayout';
import UnauthenticatedLayout from './UnauthenticatedLayout';

export default function Layout({ children }) {
    const { authenticated } = useAuth();
    const Wrapper = authenticated
        ? AuthenticatedLayout
        : UnauthenticatedLayout;

    return (
        <>
            <Wrapper>
                {children}
            </Wrapper>
            <Modal />
        </>
    );
}
