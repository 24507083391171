import React from 'react';
import Message from './Message';
import Overview from './Overview';
import useAuth from '../../hooks/useAuth';
import { useAuthenticatedHttpGet } from '../../hooks/useHttp';

export default function Products() {
    const { user } = useAuth();
    const {
        loading,
        error,
        ready,
        data,
    } = useAuthenticatedHttpGet(`/api/products/favorites/${user.defaultUnit.id}`);
    const products = data ?? [];

    return (
        <>
            {loading && <Message text="Hämtar favoriter ..." />}
            {error && <Message text="Kunde inte hämta favoriter" />}
            {ready && products.length === 0 && <Message text="Du har inga tillgängliga favoritvaror" />}
            {ready && products.length > 0 && <Overview products={products} />}
        </>
    );
}
