import React, { useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import BellIcon from '@heroicons/react/outline/BellIcon';
import usePolling from '../../hooks/usePolling';
import { authenticatedHttpQuery, httpMethods } from '../../utils/http';
import { notificationStatus } from '../../utils/enums';
import Notification from './Notification';
import { getNotifications, setNotifications } from '../../utils/notifications';

export default function Notifications() {
    const [notifications, setLocalNotifications] = useState(getNotifications());

    function openNotifications() {
        if (notifications.filter((n) => n.status === notificationStatus.unseen.value).length === 0) return;
        authenticatedHttpQuery({ url: '/api/notifications/seen', options: { method: httpMethods.POST } });

        const notificationsToSet = notifications.map((n) => ({
            ...n,
            status: notificationStatus.seen.value,
        }));

        setNotifications(notificationsToSet);
        setLocalNotifications(notificationsToSet);
    }

    async function setNotificationsOnLoad() {
        const data = await authenticatedHttpQuery({ url: '/api/notifications/all', options: { method: httpMethods.GET } });
        const { errors = [] } = data;

        if (errors.length === 0) {
            setNotifications(data);
            setLocalNotifications(data);
        }
    }

    async function pollNotifications() {
        const data = await authenticatedHttpQuery({ url: '/api/notifications/all', options: { method: httpMethods.GET } });
        const { errors = [] } = data;

        if (errors.length === 0) {
            let set = false;
            for (let i = 0; i < data.length; i += 1) {
                const notification = notifications.find((n) => n.id === data[i].id);
                if (notification) {
                    if (notification.status !== data[i].status || notification.message !== data[i].message || notification.imageUrl !== data[i].imageUrl) {
                        set = true;
                    }
                } else {
                    set = true;
                }
            }

            if (set) {
                setNotifications(data);
                setLocalNotifications(data);
            }
        }
    }

    usePolling(pollNotifications, 10 * 1000);
    useEffect(setNotificationsOnLoad, []);

    return (
        <div className="relative">
            {notifications.filter((n) => n.status === notificationStatus.unseen.value).length > 0 && (
                <div className="absolute bg-red-600 h-4 w-4 rounded-full right-0 top-0 z-10" />
            )}
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="border-2 border-boxmeal-green-600 text-gray-700 p-2 rounded-full focus:outline-none z-0">
                        <div role="button" tabIndex={0} onClick={openNotifications}>
                            <BellIcon className="w-5" />
                        </div>
                    </Menu.Button>
                </div>
                <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-lg shadow-lg z-10 focus:outline-none">
                    {notifications.length === 0 && (
                        <Notification
                            status={notificationStatus.seen.value}
                            message="Du har inga olästa notiser"
                        />
                    )}
                    {notifications.length !== 0 && notifications.map((n) => (
                        <Notification
                            key={`notification-${n.id}`}
                            id={n.id}
                            imageUrl={n.imageUrl}
                            message={n.message}
                            status={n.status}
                        />
                    ))}
                </Menu.Items>
            </Menu>
        </div>
    );
}
