import React from 'react';
import AuthenticatedTopMenu from '../../components/Layout/AuthenticatedTopMenu';
import OpenChooseUnit from '../../components/Products/OpenChooseUnit';
import Products from './Products';
import useAuth from '../../hooks/useAuth';

export default function Favorites() {
    const { user } = useAuth();
    const hasChosenUnit = user?.defaultUnit?.id ?? null;
    return (
        <>
            <AuthenticatedTopMenu>
                <OpenChooseUnit />
            </AuthenticatedTopMenu>
            <div className="p-4">
                {!hasChosenUnit && (
                    <p className="text-center text-gray-500 mt-12 font-semibold">Välj butik för att se dina tillgängliga favoriter</p>
                )}
                {hasChosenUnit && (
                    <Products />
                )}
            </div>
        </>
    );
}
