import React, { useState, useEffect } from 'react';
import LightAlert from '../../components/Alerts/LightAlert';
import GreenButton from '../../components/Buttons/GreenButton';
import LightButton from '../../components/Buttons/LightButton';
import TextField from '../../components/Shared/TextField';
import { useHttpQueryTrigger } from '../../hooks/useHttp';
import keyCodes from '../../utils/events';
import { httpMethods } from '../../utils/http';

const views = { RESET: 'RESET', COMPLETE: 'COMPLETE' };
export default function ResetForm({ goToLogIn }) {
    const [view, setView] = useState(views.RESET);
    const [email, setEmail] = useState('');

    const {
        trigger,
        sending,
        result,
        error,
        done,
    } = useHttpQueryTrigger();

    function doReset() {
        const options = {
            method: httpMethods.POST,
            body: JSON.stringify({ email }),
            headers: { 'Content-Type': 'application/json' },
        };

        trigger('/api/users/reset-password', options);
    }

    function checkKey(e) {
        if (e.key === keyCodes.enter) {
            doReset();
        }
    }

    const hasError = done && (error || (result?.hasError ?? true));
    const errorMessage = (result?.errors || ['Ett fel uppstod']).join(', ');

    function handleCompletion() {
        if (done && !error) {
            if (!(result?.hasError ?? true)) {
                setView(views.COMPLETE);
            }
        }
    }

    useEffect(handleCompletion, [done, error, result, setView]);

    return (
        <>
            {view === views.RESET && (
                <>
                    <TextField
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={checkKey}
                        label="E-post"
                        placeholder="din-mail@gmail.com"
                        className="mb-3"
                    />
                    <div className="text-center mt-16">
                        <GreenButton onClick={doReset} disabled={sending}>{sending ? 'Återställer ...' : 'Återställ'}</GreenButton>
                        {hasError && (
                            <small className="block my-4 text-red-600">{errorMessage}</small>
                        )}
                        <div className="my-4" />
                        <LightButton onClick={goToLogIn} disabled={sending}>Logga in istället</LightButton>
                    </div>
                </>
            )}
            {view === views.COMPLETE && (
                <>
                    <LightAlert>
                        <h1 className="text-xl text-gray-700 text-center mb-2">Ett mail har skickats!</h1>
                        <p className="text-sm text-gray-700 text-center">Kolla din mail och gå in på länken</p>
                    </LightAlert>
                    <div className="my-8" />
                    <GreenButton onClick={goToLogIn} disabled={sending}>Gå till inlogg</GreenButton>
                </>
            )}
        </>
    );
}
