import React, { useState } from 'react';
import ChevronLeftIcon from '@heroicons/react/outline/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/outline/ChevronRightIcon';
import AuthenticatedTopMenu from '../../components/Layout/AuthenticatedTopMenu';
import OpenChooseUnit from '../../components/Products/OpenChooseUnit';
import SaveLunch from './SaveLunch';
import { useAuthenticatedHttpGet } from '../../hooks/useHttp';
import {
    getIsoYear,
    getIsoWeek,
    getMonday,
    addWeeksToDate,
} from '../../utils/datetime';

function ToggleButton({
    children,
    onClick,
    disabled,
}) {
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            className="p-2 h-8 w-8 rounded-full text-gray-500 border-2 border-boxmeal-green-500 focus:outline-none"
        >
            {children}
        </button>
    );
}

export default function Lunch() {
    const [date, setDate] = useState(addWeeksToDate(new Date(), 1));

    function addWeek() {
        setDate(addWeeksToDate(date, 1));
    }

    function subtractWeek() {
        setDate(addWeeksToDate(date, -1));
    }

    const monday = getMonday(date);
    const week = getIsoWeek(date);
    const year = getIsoYear(date);

    const {
        loading,
        error,
        ready,
        data,
    } = useAuthenticatedHttpGet(`/api/lunch/${year}/${week}`);

    return (
        <>
            <AuthenticatedTopMenu>
                <OpenChooseUnit />
            </AuthenticatedTopMenu>
            <div className="p-4">
                <div className="flex mb-4">
                    <div className="w-1/4 text-center">
                        <ToggleButton
                            disabled={loading}
                            onClick={subtractWeek}
                        >
                            <ChevronLeftIcon />
                        </ToggleButton>
                    </div>
                    <div className="w-1/2 text-center">
                        <p className="mt-1 font-semibold text-lg text-gray-700 mb-2">{`Vecka ${week} - ${year}`}</p>
                    </div>
                    <div className="w-1/4 text-center">
                        <ToggleButton
                            disabled={loading}
                            onClick={addWeek}
                        >
                            <ChevronRightIcon />
                        </ToggleButton>
                    </div>
                </div>
                {loading && (
                    <p className="text-center text-gray-500 mt-12 font-semibold">Hämtar dina bokade luncher ...</p>
                )}
                {ready && error && (
                    <p className="text-center text-gray-500 mt-12 font-semibold">Kunde inte hämta dina bokade luncher</p>
                )}
                {ready && !error && (
                    <SaveLunch
                        year={year}
                        week={week}
                        monday={monday}
                        bookedLunches={data ?? []}
                    />
                )}
            </div>
        </>
    );
}
