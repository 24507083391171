import React from 'react';
import { useHistory } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import ChevronDownIcon from '@heroicons/react/outline/ChevronDownIcon';
import ChevronUpIcon from '@heroicons/react/outline/ChevronUpIcon';
import GreenButton from '../../components/Buttons/GreenButton';
import LightButton from '../../components/Buttons/LightButton';
import AuthenticatedTopMenu from '../../components/Layout/AuthenticatedTopMenu';
import HeaderMessage from '../../components/Layout/HeaderMessage';
import { allRoutes } from '../../utils/routes';
import { useAuthenticatedHttpGet } from '../../hooks/useHttp';
import useModal from '../../hooks/useModal';

function SupportItem({ title, children }) {
    return (
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className={`${open ? 'border-t-2 border-l-2 border-r-2 rounded-t-2xl' : 'border-2 rounded-2xl'} border-boxmeal-green-600 px-4 py-3 w-full text-left text-gray-700 focus:outline-none`}>
                        <div className="flex">
                            <div className="flex-none">
                                <p className="font-bold text-gray-700">{title}</p>
                            </div>
                            <div className="flex-grow" />
                            <div className="flex-none w-6">
                                {open ? <ChevronUpIcon className="h-6" /> : <ChevronDownIcon className="h-6" />}
                            </div>
                        </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-700 border-b-2 border-l-2 border-r-2 border-boxmeal-green-600 p-4 rounded-b-2xl bg-gray-100">
                        {children}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}

export default function Support() {
    const history = useHistory();
    const { openModal } = useModal();

    const {
        loading,
        error,
        ready,
        data,
    } = useAuthenticatedHttpGet('/api/faq/all');

    function openReport() {
        openModal({
            title: 'Rapportera ett fel',
            component: 'report',
        });
    }

    return (
        <>
            <AuthenticatedTopMenu>
                <HeaderMessage text="Support" />
            </AuthenticatedTopMenu>
            <div className="p-4">
                {loading && (
                    <p className="text-center text-gray-500 mt-12 font-semibold">Hämtar dina ordrar ...</p>
                )}
                {ready && error && (
                    <p className="text-center text-gray-500 mt-12 font-semibold">Du har inga sparade ordrar</p>
                )}
                {ready && !error && data === null && (
                    <p className="text-center text-gray-500 mt-12 font-semibold">Frågorna kunde inte hittas</p>
                )}
                {ready && !error && data !== null && (data ?? []).length === 0 && (
                    <p className="text-center text-gray-500 mt-12 font-semibold">Det finns inga vanliga frågor än så länge</p>
                )}
                {ready && !error && data !== null && (data ?? []).length !== 0 && (data ?? []).map((q) => (
                    <div key={`faq-${q.id}`} className="mb-4">
                        <SupportItem title={q.title}>
                            <p className="text-gray-700">{q.answer}</p>
                        </SupportItem>
                    </div>
                ))}
                <div className="my-8" />
                <GreenButton onClick={openReport}>Rapportera ett fel</GreenButton>
                <div className="my-4" />
                <LightButton onClick={() => history.push(allRoutes.account)}>Gå tillbaka</LightButton>
            </div>
        </>
    );
}
