import React from 'react';

export default function UnauthenticatedLayout({ children }) {
    return (
        <>
            <div className="p-4 border-b-2 border-white">
                <div className="flex justify-end">
                    <img
                        src="/img/boxmeal-black.png"
                        className="h-10"
                        alt="Logotyp"
                    />
                </div>
            </div>
            {children}
        </>
    );
}
