import React from 'react';
import Qr from 'qrcode.react';
import { useHistory } from 'react-router-dom';
import GreenButton from '../../components/Buttons/GreenButton';
import { allRoutes } from '../../utils/routes';

export default function Complete({
    orderId,
    unitLockCode,
}) {
    const history = useHistory();
    return (
        <>
            <Qr
                size={280}
                className="mx-auto mb-8"
                value={unitLockCode}
            />
            <div className="text-center px-4">
                <p className="font-semibold text-gray-700 mb-2">{`Tack för din order med ordernummer: ${orderId}!`}</p>
                <p className="text-gray-700">Skanna koden ovan för att öppna kylen och ta dina varor</p>
            </div>
            <div className="my-16" />
            <GreenButton onClick={() => history.push(allRoutes.home)}>Tillbaka till butik</GreenButton>
        </>
    );
}
