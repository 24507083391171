import React from 'react';
import GreenButton from '../../components/Buttons/GreenButton';
import LightButton from '../../components/Buttons/LightButton';

export default function AreYouSure({
    goToCheckout,
    goToAssortment,
}) {
    return (
        <div className="my-4">
            <p className="font-semibold text-gray-700 text-center mb-4">Har du valt alla produkter du vill köpa?</p>
            <GreenButton onClick={goToCheckout}>Ja</GreenButton>
            <div className="my-4" />
            <LightButton onClick={goToAssortment}>Nej</LightButton>
        </div>
    );
}
