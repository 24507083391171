import React from 'react';
import LatestOrder from '../../components/Layout/LatestOrder';
import Category from './Category';

export default function Overview({ products }) {
    const categories = products
        .map((p) => p.article.category)
        .filter((value, index, self) => self.indexOf(value) === index);

    return (
        <>
            {categories.map((category, index) => (
                <Category
                    key={`category-${index}`}
                    name={category}
                    products={products.filter((p) => p.article.category === category)}
                />
            ))}
            <LatestOrder />
        </>
    );
}
