import React, { createContext, useState } from 'react';
import { getToken, getUser } from '../utils/auth';

const token = getToken();
const defaultAuthState = {
    user: getUser(),
    authenticating: false,
    authenticated: token !== null && new Date(token.validUntil) > new Date(),
    errors: [],
};

export const AuthContext = createContext(defaultAuthState);

export default function AuthProvider({ children }) {
    const [state, setState] = useState(defaultAuthState);
    return (
        <AuthContext.Provider value={{ state, setState }}>
            {children}
        </AuthContext.Provider>
    );
}
