import React from 'react';
import { Link } from 'react-router-dom';
import ShoppingBagIcon from '@heroicons/react/outline/ShoppingBagIcon';
import UserIcon from '@heroicons/react/outline/UserIcon';
import ViewGridAddIcon from '@heroicons/react/outline/ViewGridAddIcon';
import HeartIcon from '@heroicons/react/outline/HeartIcon';
import CalendarIcon from '@heroicons/react/outline/CalendarIcon';
import useStore from '../../hooks/useStore';
import { allRoutes } from '../../utils/routes';
import { categories } from '../../utils/enums';
import useAuth from '../../hooks/useAuth';

const bottomMenuSize = '86px';
export default function AuthenticatedLayout({ children }) {
    const { items } = useStore();
    const { user } = useAuth();

    const availableCategories = (user?.defaultUnit?.availableCategories ?? []);
    const hasFood = availableCategories.length !== 0 && availableCategories.some((c) => c === categories.food.value);

    return (
        <>
            <div style={{ paddingBottom: bottomMenuSize }}>
                {children}
            </div>
            <div className="fixed bg-white bottom-0 w-full py-4 border-t-2 border-gray-100">
                <div className={`grid ${hasFood ? 'grid-cols-5' : 'grid-cols-4'} gap-4`}>
                    <Link to={allRoutes.home} className="focus:outline-0 text-center text-gray-700 focus:text-boxmeal-green-600">
                        <ViewGridAddIcon className="w-6 mb-1 mx-auto" />
                        <small>Butik</small>
                    </Link>
                    <Link to={allRoutes.favorites} className="focus:outline-0 text-center text-gray-700 focus:text-boxmeal-green-600">
                        <HeartIcon className="w-6 mb-1 mx-auto" />
                        <small>Favoriter</small>
                    </Link>
                    <Link to={allRoutes.checkout} className="focus:outline-0 text-center text-gray-700 focus:text-boxmeal-green-600">
                        <div className="relative">
                            {items.length > 0 && (
                                <div className="absolute -right-1 -top-3 bg-boxmeal-green-600 rounded-full h-8 w-8 flex items-center justify-center">
                                    <p className="text-gray-50">{items.length}</p>
                                </div>
                            )}
                            <ShoppingBagIcon className="w-6 mb-1 mx-auto" />
                            <small>Kassa</small>
                        </div>
                    </Link>
                    {hasFood && (
                        <Link to={allRoutes.lunch} className="focus:outline-0 text-center text-gray-700 focus:text-boxmeal-green-600">
                            <CalendarIcon className="w-6 mb-1 mx-auto" />
                            <small>Lunch</small>
                        </Link>
                    )}
                    <Link to={allRoutes.account} className="focus:outline-0 text-center text-gray-700 focus:text-boxmeal-green-600">
                        <UserIcon className="w-6 mb-1 mx-auto" />
                        <small>Konto</small>
                    </Link>
                </div>
            </div>
        </>
    );
}
