import React from 'react';
import Notifications from './Notifications';

export default function AuthenticatedTopMenu({ children }) {
    return (
        <div className="p-4 border-b-2 border-gray-50">
            <div className="flex">
                <div className="flex-wrap content-center">
                    <Notifications />
                </div>
                <div className="flex-grow">{children}</div>
                <div className="justify-end">
                    <img
                        src="/img/boxmeal-black.png"
                        className="h-10"
                        alt="Logotyp"
                    />
                </div>
            </div>
        </div>
    );
}
