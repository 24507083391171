import React from 'react';
import useModal from '../../hooks/useModal';
import LightButton from '../Buttons/LightButton';

export default function Notification({ imageUrl, message }) {
    const { closeModal } = useModal();
    return (
        <div className="mt-4">
            {(imageUrl !== null) && (
                <img src={imageUrl} alt="Bild för notifikationen" className="mb-4" />
            )}
            <p className="text-gray-500 mb-4">{message}</p>
            <LightButton onClick={closeModal}>Stäng</LightButton>
        </div>
    );
}
