import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpQueryTrigger } from '../../hooks/useHttp';
import { allRoutes, getRouteParameter } from '../../utils/routes';
import GreenButton from '../../components/Buttons/GreenButton';
import LightButton from '../../components/Buttons/LightButton';
import TextField from '../../components/Shared/TextField';
import useAuth from '../../hooks/useAuth';
import keyCodes from '../../utils/events';
import { httpMethods } from '../../utils/http';

export default function CompleteReset() {
    const history = useHistory();
    const { setAuthenticated } = useAuth();
    const [password, setPassword] = useState('');

    function goToLogIn() {
        history.push(allRoutes.login);
    }

    const {
        trigger,
        sending,
        result,
        error,
        done,
    } = useHttpQueryTrigger();

    function doPasswordReset() {
        const options = {
            method: httpMethods.POST,
            body: JSON.stringify({ password }),
            headers: { 'Content-Type': 'application/json' },
        };

        const token = getRouteParameter('token');
        trigger(`/api/users/complete-password-reset/${token}`, options);
    }

    function checkKey(e) {
        if (e.key === keyCodes.enter) {
            doPasswordReset();
        }
    }

    const hasError = done && (error || (result?.hasError ?? true));
    const errorMessage = (result?.errors || ['Ett fel uppstod']).join(', ');

    function handleLogin() {
        if (done && !error) {
            if (!(result?.hasError ?? true)) {
                setAuthenticated(result.data);
                history.push(allRoutes.home);
            }
        }
    }

    useEffect(handleLogin, [done, error, result, history, setAuthenticated]);

    return (
        <div className="px-4 pb-4">
            <TextField
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={checkKey}
                label="Nytt lösenord"
                placeholder="Nytt lösenord"
                autoComplete="new-password"
            />
            <div className="text-center mt-16">
                <GreenButton onClick={doPasswordReset} disabled={sending}>{sending ? 'Återställer ...' : 'Återställ'}</GreenButton>
                {hasError && (
                    <small className="block my-4 text-red-600">{errorMessage}</small>
                )}
                <div className="my-4" />
                <LightButton onClick={goToLogIn} disabled={sending}>Logga in istället</LightButton>
            </div>
        </div>
    );
}
