import React, { useEffect } from 'react';
import useStore from '../../hooks/useStore';
import GreenButton from '../../components/Buttons/GreenButton';
import Item from './Item';
import { useAuthenticatedHttpQueryTrigger } from '../../hooks/useHttp';
import { httpMethods } from '../../utils/http';

export default function Items({ setOrder }) {
    const { items, clearItems } = useStore();

    const products = [];
    for (let i = 0; i < items.length; i += 1) {
        if (!products.some((p) => p.id === items[i].id)) {
            products.push(items[i]);
        }
    }

    const {
        trigger,
        sending,
        result,
        error,
        done,
    } = useAuthenticatedHttpQueryTrigger();

    function makeOrder() {
        const purchase = [];
        for (let i = 0; i < items.length; i += 1) {
            const index = purchase.findIndex(((p) => p.productId === items[i].id));

            if (index === -1) {
                purchase.push({
                    productId: items[i].id,
                    amount: 1,
                });
            } else {
                purchase[index].amount += 1;
            }
        }

        const options = {
            method: httpMethods.POST,
            body: JSON.stringify({ products: purchase }),
            headers: { 'Content-Type': 'application/json' },
        };

        trigger('/api/orders/add', options);
    }

    const hasError = done && (error || (result?.hasError ?? true));
    const errorMessage = (result?.errors || ['Ett fel uppstod']).join(', ');

    function handleOrder() {
        if (done && !error) {
            if (!(result?.hasError ?? true)) {
                setOrder(result.data.id, result.data.token);
            }
        }
    }

    useEffect(handleOrder, [done, error, result, setOrder]);

    return (
        <>
            {products.map((p) => (
                <Item
                    key={`checkout-product-${p.id}`}
                    product={p}
                />
            ))}
            <div className="text-center pt-2">
                <button
                    type="button"
                    onClick={clearItems}
                    className="text-sm text-gray-500 py-2 px-3 rounded-full border-2 border-gray-500 font-medium"
                >
                    Rensa varukorg
                </button>
            </div>
            <p className="text-gray-700 font-semibold text-xl mt-5 mb-8 text-center">{`Totalt: ${items.map((p) => p.article.price).reduce((a, b) => a + b, 0)} :-`}</p>
            <GreenButton disabled={sending} onClick={makeOrder}>{sending ? 'Skickar till Swish ...' : 'Betala med Swish'}</GreenButton>
            {hasError && (
                <small className="block my-4 text-red-600 text-center">{errorMessage}</small>
            )}
        </>
    );
}
