import React from 'react';
import { useHistory } from 'react-router-dom';
import GreenButton from '../../components/Buttons/GreenButton';
import { allRoutes } from '../../utils/routes';

export default function NotFound() {
    const history = useHistory();
    return (
        <div className="w-60 text-center my-8 mx-auto">
            <p className="text-lg text-gray-700 mb-4">404 - Sidan hittades inte!</p>
            <GreenButton onClick={() => history.push(allRoutes.home)}>Gå till första sidan</GreenButton>
        </div>
    );
}
