import React from 'react';
import AuthenticatedTopMenu from '../../components/Layout/AuthenticatedTopMenu';
import OpenChooseUnit from '../../components/Products/OpenChooseUnit';
import Message from './Message';
import Unit from './Unit';
import useAuth from '../../hooks/useAuth';

export default function Home() {
    const { user } = useAuth();
    const hasChosenUnit = user?.defaultUnit?.id ?? null;
    return (
        <>
            <AuthenticatedTopMenu>
                <OpenChooseUnit />
            </AuthenticatedTopMenu>
            <div className="pt-4 px-4">
                {!hasChosenUnit && <Message text="Du har inte valt butik än" />}
                {hasChosenUnit && <Unit />}
            </div>
        </>
    );
}
