const TOKEN_NAMESPACE = 'AUTH_TOKEN_BOXMEAL_SHOP';
const USER_NAMESPACE = 'AUTH_USER_BOXMEAL_SHOP';

export function getToken() {
    const token = localStorage.getItem(TOKEN_NAMESPACE) ?? null;
    return token === null ? null : JSON.parse(token);
}

export function setToken(token) {
    localStorage.setItem(TOKEN_NAMESPACE, JSON.stringify(token));
}

export function clearToken() {
    localStorage.removeItem(TOKEN_NAMESPACE);
}

export function getUser() {
    const user = localStorage.getItem(USER_NAMESPACE) ?? null;
    return user === null ? null : JSON.parse(user);
}

export function setUser(user) {
    localStorage.setItem(USER_NAMESPACE, JSON.stringify(user));
}

export function clearUser() {
    localStorage.removeItem(USER_NAMESPACE);
}
