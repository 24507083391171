import React, { createContext, useState } from 'react';

const defaultModalState = {
    open: false,
    title: '',
    component: '',
    componentProps: {},
};

export const ModalContext = createContext(defaultModalState);

export default function ModalProvider({ children }) {
    const [modal, setModal] = useState(defaultModalState);
    return (
        <ModalContext.Provider value={{ modal, setModal }}>
            {children}
        </ModalContext.Provider>
    );
}
