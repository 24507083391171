import React, { useState } from 'react';
import AuthenticatedTopMenu from '../../components/Layout/AuthenticatedTopMenu';
import HeaderMessage from '../../components/Layout/HeaderMessage';
import Complete from './Complete';
import Pending from './Pending';
import Error from './Error';
import useStore from '../../hooks/useStore';
import { orderStatus } from '../../utils/enums';
import useAuth from '../../hooks/useAuth';

const views = {
    ERROR: 'ERROR',
    PENDING: 'PENDING',
    COMPLETE: 'COMPLETE',
};

export default function Confirmation({ match }) {
    const { orderId = '' } = match.params;
    const { clearItems } = useStore();
    const { authenticated } = useAuth();

    const [state, setState] = useState({
        view: views.PENDING,
        unitLockCode: '',
    });

    function setOrderData(status, code) {
        if (status === orderStatus.open.value || status === orderStatus.pending.value) {
            return;
        }

        if (status === orderStatus.paid.value) {
            clearItems();
        }

        setState((prev) => ({
            ...prev,
            view: status === orderStatus.cancelled.value ? views.ERROR : views.COMPLETE,
            unitLockCode: code,
        }));
    }

    return (
        <>
            {authenticated && (
                <AuthenticatedTopMenu>
                    <HeaderMessage text="Kassa" />
                </AuthenticatedTopMenu>
            )}
            <div className="p-4">
                {state.view === views.PENDING && (
                    <Pending orderId={orderId} setOrderData={setOrderData} />
                )}
                {state.view === views.ERROR && (
                    <Error orderId={orderId} />
                )}
                {state.view === views.COMPLETE && (
                    <Complete orderId={orderId} unitLockCode={state.unitLockCode} />
                )}
            </div>
        </>
    );
}
