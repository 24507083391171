import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { allRoutes } from './utils/routes';
import PrivateRoute from './components/Auth/PrivateRoute';
import PublicRoute from './components/Auth/PublicRoute';
import Layout from './components/Layout/Layout';
import Start from './routes/Start/Start';
import Login from './routes/Auth/LogIn';
import CompleteReset from './routes/Auth/CompleteReset';
import Register from './routes/Register/Register';
import Home from './routes/Home/Home';
import Account from './routes/Account/Account';
import Checkout from './routes/Checkout/Checkout';
import Settings from './routes/Account/Settings';
import Support from './routes/Account/Support';
import History from './routes/Account/History';
import Favorites from './routes/Favorites/Favorites';
import Confirmation from './routes/Order/Confirmation';
import Lunch from './routes/Lunch/Lunch';
import NotFound from './routes/404/NotFound';

export default function App() {
    return (
        <Router>
            <Layout>
                <Switch>
                    <Route exact path={`${allRoutes.confirmation}/:orderId`} component={Confirmation} />
                    <PublicRoute exact path={allRoutes.start} component={Start} />
                    <PublicRoute exact path={allRoutes.login} component={Login} />
                    <Route exact path={allRoutes.completeReset} component={CompleteReset} />
                    <PublicRoute exact path={allRoutes.register} component={Register} />
                    <PrivateRoute exact path={allRoutes.home} component={Home} />
                    <PrivateRoute exact path={allRoutes.account} component={Account} />
                    <PrivateRoute exact path={allRoutes.history} component={History} />
                    <PrivateRoute exact path={allRoutes.support} component={Support} />
                    <PrivateRoute exact path={allRoutes.settings} component={Settings} />
                    <PrivateRoute exact path={allRoutes.checkout} component={Checkout} />
                    <PrivateRoute exact path={allRoutes.favorites} component={Favorites} />
                    <PrivateRoute exact path={allRoutes.lunch} component={Lunch} />
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        </Router>
    );
}
