import { useEffect } from 'react';

function usePolling(poll, ms = 2000) {
    useEffect(() => {
        const interval = setInterval(() => {
            poll();
        }, ms);

        return () => clearInterval(interval);
    }, []);
}

export default usePolling;
