import React from 'react';
import MinusIcon from '@heroicons/react/outline/MinusIcon';
import PlusIcon from '@heroicons/react/outline/PlusIcon';
import useStore from '../../hooks/useStore';

export default function Item({ product }) {
    const {
        items,
        addItem,
        removeItem,
    } = useStore();

    const amountInBag = items.filter((item) => item.id === product.id).length;

    function addItemToBag() {
        if (amountInBag < product.currentStock) {
            addItem(product);
        }
    }

    function removeItemFromBag() {
        removeItem(product);
    }

    return (
        <div className="shadow-lg rounded-2xl mb-2 py-2">
            <div className="flex">
                <div role="button" tabIndex={0} className="flex-none pt-8 pl-2 focus:outline-none" onClick={removeItemFromBag}>
                    <div className="border-2 border-gray-400 text-gray-700 p-2 rounded-full">
                        <MinusIcon className="w-4" />
                    </div>
                </div>
                <div className="flex-grow">
                    <div className="flex">
                        <img src={product.article.imageUrl} className="h-24 mx-auto" alt={product.article.name} />
                        <div className="mt-4">
                            <p className="text-gray-700">{`${amountInBag} x ${product.article.name}`}</p>
                            <p className="text-gray-700 font-bold">{`${amountInBag} x ${product.article.price} :-`}</p>
                        </div>
                    </div>
                </div>
                <div role="button" tabIndex={0} className="flex-none pt-8 px-2 focus:outline-none" onClick={addItemToBag}>
                    <div className="border-2 border-gray-400 text-gray-700 p-2 rounded-full">
                        <PlusIcon className="w-4" />
                    </div>
                </div>
            </div>
        </div>
    );
}
