import React, { Fragment } from 'react';
import XIcon from '@heroicons/react/outline/XIcon';
import { Dialog, Transition } from '@headlessui/react';
import LightButton from '../Buttons/LightButton';
import useModal from '../../hooks/useModal';
import Order from '../Orders/Order';
import ChooseUnit from '../Products/ChooseUnit';
import Notification from '../Notifications/Notification';
import Info from '../Products/Info';
import Report from '../Reports/Report';
import TermsOfAgreement from '../../routes/Register/TermsOfAgreement';

const components = {
    order: Order,
    report: Report,
    productInfo: Info,
    chooseUnit: ChooseUnit,
    notification: Notification,
    termsOfAgreement: TermsOfAgreement,
};

function UnknownComponent() {
    const { closeModal } = useModal();
    return (
        <div className="mt-4">
            <p className="text-gray-500 mb-8">Komponenten du försökte hitta finns inte. Kontakta vår support så kan vi hjälpa dig.</p>
            <LightButton onClick={closeModal}>Stäng</LightButton>
        </div>
    );
}

export default function Modal() {
    const {
        open,
        closeModal,
        title,
        component,
        componentProps,
    } = useModal();

    const Component = components[component];

    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-4 my-4 text-left overflow-hidden transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title as="div" className="grid grid-cols-8 gap-4">
                                    <div className="col-span-7">
                                        <p className="text-lg font-medium text-gray-700">{title}</p>
                                    </div>
                                    <div className="col-span-1">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="p-2 h-8 w-8 rounded-full text-gray-500 border-2 border-gray-200 hover:border-boxmeal-green-600 focus:outline-none"
                                        >
                                            <XIcon />
                                        </button>
                                    </div>
                                </Dialog.Title>
                                {Component === undefined ? <UnknownComponent /> : <Component {...componentProps} />}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
