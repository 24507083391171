import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthenticatedTopMenu from '../../components/Layout/AuthenticatedTopMenu';
import HeaderMessage from '../../components/Layout/HeaderMessage';
import useStore from '../../hooks/useStore';
import AreYouSure from './AreYouSure';
import Payment from './Payment';
import Items from './Items';
import Message from './Message';
import { allRoutes } from '../../utils/routes';

const views = {
    ARE_YOU_SURE: 'ARE_YOU_SURE',
    CHECKOUT: 'CHECKOUT',
    PAYMENT: 'PAYMENT',
};

export default function Checkout() {
    const history = useHistory();

    const [state, setState] = useState({
        view: Math.floor(Math.random() * 4) !== 0 ? views.CHECKOUT : views.ARE_YOU_SURE,
        orderId: null,
        token: '',
    });

    function goToCheckout() {
        setState((prev) => ({
            ...prev,
            view: views.CHECKOUT,
        }));
    }

    function setOrder(orderId, token) {
        setState((prev) => ({
            ...prev,
            view: views.PAYMENT,
            orderId,
            token,
        }));
    }

    function goToAssortment() {
        history.push(allRoutes.home);
    }

    const { items } = useStore();

    return (
        <>
            <AuthenticatedTopMenu>
                <HeaderMessage text="Kassa" />
            </AuthenticatedTopMenu>
            <div className="p-4">
                {state.view === views.ARE_YOU_SURE && (
                    <AreYouSure
                        goToCheckout={goToCheckout}
                        goToAssortment={goToAssortment}
                    />
                )}
                {state.view === views.CHECKOUT && (
                    <>
                        {items.length === 0 && <Message text="Du har inga varor i kassan" />}
                        {items.length > 0 && <Items setOrder={setOrder} />}
                    </>
                )}
                {state.view === views.PAYMENT && (
                    <Payment orderId={state.orderId} token={state.token} />
                )}
            </div>
        </>
    );
}
