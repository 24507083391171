export default function localeCurrency(amount = 0, minimumFractionDigits = 2, maximumFractionDigits = 2) {
    if (amount === null || amount === undefined) amount = 0;
    if (typeof amount === 'string') amount = parseInt(amount, 10);
    const options = {
        style: 'currency',
        currency: 'SEK',
        currencyDisplay: 'symbol',
        maximumFractionDigits,
        minimumFractionDigits,
    };

    return amount.toLocaleString('sv-SE', options);
}
