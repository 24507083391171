import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import GreenButton from '../../components/Buttons/GreenButton';
import LightButton from '../../components/Buttons/LightButton';
import TextField from '../../components/Shared/TextField';
import { useHttpQueryTrigger } from '../../hooks/useHttp';
import { allRoutes } from '../../utils/routes';
import useAuth from '../../hooks/useAuth';
import useModal from '../../hooks/useModal';
import { httpMethods } from '../../utils/http';

export default function Register() {
    const { setAuthenticated } = useAuth();
    const { openModal } = useModal();
    const history = useHistory();

    const [user, editUser] = useState({
        givenName: '',
        surname: '',
        phoneNumber: '',
        email: '',
        password: '',
        passwordRepeat: '',
        preferredStoreIdentifier: '',
        readTerms: false,
    });

    function editDetails(e) {
        const { name, value } = e.target;
        editUser((u) => ({
            ...u,
            [name]: value,
        }));
    }

    function editPreferredStoreIdentifier(e) {
        const { value } = e.target;
        if (value.length <= 4) {
            editUser((u) => ({
                ...u,
                preferredStoreIdentifier: value.toUpperCase(),
            }));
        }
    }

    function setReadTerms(readTerms) {
        editUser((u) => ({
            ...u,
            readTerms,
        }));
    }

    const {
        trigger,
        sending,
        result,
        error,
        done,
    } = useHttpQueryTrigger();

    function doRegister() {
        const options = {
            method: httpMethods.POST,
            body: JSON.stringify({
                givenName: user.givenName,
                surname: user.surname,
                phoneNumber: user.phoneNumber,
                email: user.email,
                password: user.password,
                preferredStoreIdentifier: user.preferredStoreIdentifier,
            }),
            headers: { 'Content-Type': 'application/json' },
        };

        trigger('/api/users/register', options);
    }

    const hasError = done && (error || (result?.hasError ?? true));
    const errorMessage = (result?.errors || ['Ett fel uppstod']).join(', ');

    function handleCompletion() {
        if (done && !error) {
            if (!(result?.hasError ?? true)) {
                setAuthenticated(result.data);
                history.push(allRoutes.home);
            }
        }
    }

    useEffect(handleCompletion, [done, error, result, setAuthenticated, history]);

    return (
        <div className="px-4 pb-4">
            <TextField
                type="text"
                name="givenName"
                value={user.givenName}
                onChange={editDetails}
                label="Förnamn"
                placeholder="Anna"
            />
            <div className="my-4" />
            <TextField
                type="text"
                name="surname"
                value={user.surname}
                onChange={editDetails}
                label="Efternamn"
                placeholder="Annasson"
            />
            <div className="my-4" />
            <TextField
                type="tel"
                name="phoneNumber"
                value={user.phoneNumber}
                onChange={editDetails}
                label="Telefonnummer"
                placeholder="073 123 56 70"
            />
            <div className="my-4" />
            <TextField
                type="email"
                name="email"
                value={user.email}
                onChange={editDetails}
                label="E-post"
                placeholder="din-mail@gmail.com"
                autoComplete="username"
            />
            <div className="my-4" />
            <TextField
                type="password"
                name="password"
                value={user.password}
                onChange={editDetails}
                label="Lösenord"
                placeholder="Lösenord"
                autoComplete="new-password"
            />
            <div className="my-4" />
            <TextField
                type="password"
                name="passwordRepeat"
                value={user.passwordRepeat}
                onChange={editDetails}
                label="Upprepa lösenord"
                placeholder="Upprepa lösenord"
                autoComplete="new-password"
            />
            <div className="my-4" />
            <TextField
                type="text"
                name="preferredStoreIdentifier"
                value={user.preferredStoreIdentifier}
                onChange={editPreferredStoreIdentifier}
                label="Butik-ID"
                placeholder="4 siffror"
            />
            <div className="flex my-8 px-2">
                <div className="flex-none w-16 pr-2">
                    <Switch
                        checked={user.readTerms}
                        onChange={setReadTerms}
                        className={`${user.readTerms ? 'bg-boxmeal-green-600 border-2 border-boxmeal-green-600' : 'bg-gray-500 border-2 border-gray-600'} mr-3 relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none`}
                    >
                        <span className="sr-only">Godkänn villkor</span>
                        <span className={`${user.readTerms ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 transform bg-white rounded-full`} />
                    </Switch>
                </div>
                <div className="flex-grow pl-2">
                    <small className="text-gray-700">
                        Jag godkänner Boxmeals <span role="button" tabIndex={0} onClick={() => openModal({ title: 'Allmänna villkor', component: 'termsOfAgreement' })} className="underline">allmänna villkor</span>
                    </small>
                </div>
            </div>
            <div className="text-center">
                <GreenButton onClick={doRegister} disabled={sending || !user.readTerms}>{sending ? 'Registrerar ...' : 'Registrera'}</GreenButton>
                {hasError && (
                    <small className="block my-4 text-red-600">{errorMessage}</small>
                )}
                <div className="my-4" />
                <LightButton onClick={() => history.push(allRoutes.login)} disabled={sending}>Logga in istället</LightButton>
            </div>
        </div>
    );
}
