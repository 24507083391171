import React from 'react';
import Qr from 'qrcode.react';
import { useAuthenticatedHttpGet } from '../../hooks/useHttp';
import { toDisplayDateTime } from '../../utils/datetime';
import localeCurrency from '../../utils/numbers';

export default function Order({ id }) {
    const {
        loading,
        error,
        ready,
        data,
    } = useAuthenticatedHttpGet(`/api/orders/${id}`);

    return (
        <>
            {loading && (
                <p className="text-gray-500">Hämtar order ...</p>
            )}
            {error && (
                <p className="text-gray-500">Ett fel uppstod</p>
            )}
            {ready && !error && data === null && (
                <p className="text-gray-500">Ordern kunde inte hittas</p>
            )}
            {ready && !error && data !== null && (
                <div className="mt-4">
                    {data.unlockCode !== null && (
                        <Qr
                            size={280}
                            className="mx-auto mb-8"
                            value={data.unlockCode}
                        />
                    )}
                    <p className="text-gray-700 font-medium mb-1">Totalpris</p>
                    <p className="text-gray-500 mb-4">{localeCurrency(data.totalPrice)}</p>
                    <p className="text-gray-700 font-medium mb-1">Datum och tid för order</p>
                    <p className="text-gray-500 mb-4">{toDisplayDateTime(new Date(data.createdAt))}</p>
                    <p className="text-gray-700 font-medium mb-1">Produkter</p>
                    {data.orderLines.map((ol, index) => (
                        <p className="text-gray-500 mb-1">{`${index + 1}. ${ol.article.name} - ${localeCurrency(ol.price)} - ${ol.amount} st.`}</p>
                    ))}
                </div>
            )}
        </>
    );
}
