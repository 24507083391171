import React from 'react';
import { useHistory } from 'react-router-dom';
import GreenButton from '../../components/Buttons/GreenButton';
import { allRoutes } from '../../utils/routes';

export default function Error({ orderId }) {
    const history = useHistory();
    return (
        <>
            <div className="text-center px-4">
                <p className="font-semibold text-gray-700 mb-2">{`Kunde inte ta betalt för din order med ordernummer: ${orderId}`}</p>
                <p className="text-gray-700">Vi har sparat det här ärendet, om du vill prova igen så är din order sparad</p>
            </div>
            <div className="my-16" />
            <GreenButton onClick={() => history.push(allRoutes.home)}>Tillbaka till butik</GreenButton>
        </>
    );
}
