import React from 'react';
import { useHistory } from 'react-router-dom';
import CogIcon from '@heroicons/react/outline/CogIcon';
import DocumentTextIcon from '@heroicons/react/outline/DocumentTextIcon';
import SupportIcon from '@heroicons/react/outline/SupportIcon';
import GreenButton from '../../components/Buttons/GreenButton';
import LightButton from '../../components/Buttons/LightButton';
import AuthenticatedTopMenu from '../../components/Layout/AuthenticatedTopMenu';
import HeaderMessage from '../../components/Layout/HeaderMessage';
import useAuth from '../../hooks/useAuth';
import { allRoutes } from '../../utils/routes';

export default function Account() {
    const { logOut } = useAuth();
    const history = useHistory();

    return (
        <>
            <AuthenticatedTopMenu>
                <HeaderMessage text="Mitt konto" />
            </AuthenticatedTopMenu>
            <div className="p-4">
                <LightButton onClick={() => history.push(allRoutes.history)}>
                    <div className="flex">
                        <div className="flex-none">
                            Köphistorik
                        </div>
                        <div className="flex-grow" />
                        <div className="flex-none w-6">
                            <DocumentTextIcon className="h-6" />
                        </div>
                    </div>
                </LightButton>
                <div className="my-4" />
                <LightButton onClick={() => history.push(allRoutes.support)}>
                    <div className="flex">
                        <div className="flex-none">
                            Support
                        </div>
                        <div className="flex-grow" />
                        <div className="flex-none w-6">
                            <SupportIcon className="h-6" />
                        </div>
                    </div>
                </LightButton>
                <div className="my-4" />
                <LightButton onClick={() => history.push(allRoutes.settings)}>
                    <div className="flex">
                        <div className="flex-none">
                            Dina inställningar
                        </div>
                        <div className="flex-grow" />
                        <div className="flex-none w-6">
                            <CogIcon className="h-6" />
                        </div>
                    </div>
                </LightButton>
                <div className="my-16" />
                <GreenButton onClick={logOut}>Logga ut</GreenButton>
            </div>
        </>
    );
}
