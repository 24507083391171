import React from 'react';

export default function TextArea({
    name,
    value,
    onChange,
    onKeyDown,
    type = 'text',
    label = '',
    placeholder = '',
    rows = 4,
    disabled = false,
}) {
    return (
        <>
            {label !== '' && (
                <small className="block ml-4 mb-1 font-semibold text-gray-700">{label}</small>
            )}
            <textarea
                rows={rows}
                name={name}
                type={type}
                value={value}
                disabled={disabled}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                className="text-gray-700 border-2 border-gray-400 py-3 px-4 rounded-3xl w-full focus:border-boxmeal-green-600 focus:outline-none disabled:opacity-50"
            />
        </>
    );
}
