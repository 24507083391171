import { useContext } from 'react';
import { setItems as setItemsInCache } from '../utils/store';
import { StoreContext } from '../contexts/StoreContext';

export default function useStore() {
    const { items, setItems } = useContext(StoreContext);

    function addItem(product) {
        const shoppingBag = [...items, product];
        setItems(shoppingBag);
        setItemsInCache(shoppingBag);
    }

    function removeItem(product) {
        const firstOccurrence = items.findIndex((p) => p.id === product.id);
        if (firstOccurrence !== -1) {
            const shoppingBag = items.filter((_, index) => index !== firstOccurrence);
            setItems(shoppingBag);
            setItemsInCache(shoppingBag);
        }
    }

    function clearItems() {
        const shoppingBag = [];
        setItems(shoppingBag);
        setItemsInCache(shoppingBag);
    }

    return {
        items,
        addItem,
        removeItem,
        clearItems,
    };
}
