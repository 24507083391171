import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AuthProvider from './contexts/AuthContext';
import StoreProvider from './contexts/StoreContext';
import ModalProvider from './contexts/ModalContext';

ReactDOM.render(
    <React.StrictMode>
        <ModalProvider>
            <AuthProvider>
                <StoreProvider>
                    <App />
                </StoreProvider>
            </AuthProvider>
        </ModalProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
