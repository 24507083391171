import React from 'react';
import Item from '../../components/Products/Item';

export default function Overview({ products = [] }) {
    return (
        <div className="grid grid-cols-2 gap-4">
            {products.map((p) => (
                <Item
                    key={`favorite-product-${p.id}`}
                    product={p}
                />
            ))}
        </div>
    );
}
