import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import TextField from '../Shared/TextField';
import GreenButton from '../Buttons/GreenButton';
import useModal from '../../hooks/useModal';
import { useAuthenticatedHttpQueryTrigger } from '../../hooks/useHttp';
import { httpMethods } from '../../utils/http';

export default function ChooseUnit() {
    const { user, setPreferredUnit } = useAuth();
    const { closeModal } = useModal();

    const {
        trigger,
        sending,
        result,
        error,
        done,
    } = useAuthenticatedHttpQueryTrigger();

    const [identifier, setIdentifier] = useState(user?.defaultUnit?.storeIdentifier ?? '');

    function updateIdentifier(e) {
        if (e.target.value.length <= 4) {
            setIdentifier(e.target.value.toUpperCase());
        }
    }

    function savePreferredUnit() {
        const options = {
            method: httpMethods.POST,
            body: JSON.stringify({ storeIdentifier: identifier }),
            headers: { 'Content-Type': 'application/json' },
        };

        trigger('/api/users/set-preferred-unit', options);
    }

    function handleSave() {
        if (done && !error) {
            const { hasError = true, data = {} } = result ?? {};
            if (!hasError) {
                setPreferredUnit(data);
                closeModal();
            }
        }
    }

    useEffect(handleSave, [done, error, result, closeModal, setPreferredUnit]);

    const hasError = done && (error || (result?.hasError ?? true));
    const errorMessage = (result?.errors || ['Ett fel uppstod']).join(', ');

    return (
        <div className="mt-4">
            <TextField
                label="Butikens fyrsiffriga kod"
                placeholder="BX12"
                value={identifier}
                onChange={updateIdentifier}
            />
            <div className="my-4" />
            <GreenButton onClick={savePreferredUnit} disabled={sending}>{sending ? 'Sparar ...' : 'Spara'}</GreenButton>
            {hasError && (
                <small className="block mt-4 text-center text-red-600">{errorMessage}</small>
            )}
        </div>
    );
}
