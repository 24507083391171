import React from 'react';
import { useHistory } from 'react-router-dom';
import GreenButton from '../../components/Buttons/GreenButton';
import LightButton from '../../components/Buttons/LightButton';
import AuthenticatedTopMenu from '../../components/Layout/AuthenticatedTopMenu';
import HeaderMessage from '../../components/Layout/HeaderMessage';
import { useAuthenticatedHttpGet } from '../../hooks/useHttp';
import { allRoutes } from '../../utils/routes';
import { toDisplayDate } from '../../utils/datetime';
import localeCurrency from '../../utils/numbers';
import useModal from '../../hooks/useModal';

export default function History() {
    const history = useHistory();
    const { openModal } = useModal();

    function openOrder(id) {
        openModal({
            title: `Order #${id}`,
            component: 'order',
            componentProps: { id },
        });
    }

    const {
        loading,
        error,
        ready,
        data,
    } = useAuthenticatedHttpGet('/api/orders');
    const orders = data ?? [];

    return (
        <>
            <AuthenticatedTopMenu>
                <HeaderMessage text="Köphistorik" />
            </AuthenticatedTopMenu>
            <div className="p-4">
                {loading && (
                    <p className="text-center text-gray-500 mt-12 font-semibold">Hämtar dina ordrar ...</p>
                )}
                {ready && error && (
                    <p className="text-center text-gray-500 mt-12 font-semibold">Du har inga sparade ordrar</p>
                )}
                {ready && !error && (
                    <>
                        {orders.length === 0 && (
                            <p className="text-center text-gray-500 mt-12 font-semibold">Du har inga sparade ordrar</p>
                        )}
                        {orders.length > 0 && orders.map((order) => (
                            <div key={`order-${order.id}`} className="mb-4">
                                <LightButton onClick={() => openOrder(order.id)}>
                                    <div className="flex">
                                        <div className="flex-none">
                                            {toDisplayDate(new Date(order.createdAt))}
                                        </div>
                                        <div className="flex-grow" />
                                        <div className="flex-none">
                                            {localeCurrency(order.totalPrice)}
                                        </div>
                                    </div>
                                </LightButton>
                            </div>
                        ))}
                    </>
                )}
                <div className="my-16" />
                <GreenButton onClick={() => history.push(allRoutes.account)}>Gå tillbaka</GreenButton>
            </div>
        </>
    );
}
