import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GreenButton from '../../components/Buttons/GreenButton';
import LightButton from '../../components/Buttons/LightButton';
import AuthenticatedTopMenu from '../../components/Layout/AuthenticatedTopMenu';
import HeaderMessage from '../../components/Layout/HeaderMessage';
import TextField from '../../components/Shared/TextField';
import useAuth from '../../hooks/useAuth';
import { useAuthenticatedHttpQueryTrigger } from '../../hooks/useHttp';
import { httpMethods } from '../../utils/http';
import { allRoutes } from '../../utils/routes';

export default function Settings() {
    const { user, setUserDetails } = useAuth();
    const history = useHistory();

    const [details, setDetails] = useState({
        email: user?.email ?? '',
        givenName: user?.givenName ?? '',
        surname: user?.surname ?? '',
        phoneNumber: user?.phoneNumber ?? '',
        preferredStoreIdentifier: user?.defaultUnit?.storeIdentifier ?? '',
    });

    function editDetails(e) {
        const { name, value } = e.target;
        setDetails((u) => ({
            ...u,
            [name]: value,
        }));
    }

    function editPreferredStoreIdentifier(e) {
        if (e.target.value.length <= 4) {
            setDetails((u) => ({
                ...u,
                preferredStoreIdentifier: e.target.value.toUpperCase(),
            }));
        }
    }

    const {
        trigger,
        sending,
        result,
        error,
        done,
    } = useAuthenticatedHttpQueryTrigger();

    function doUpdate() {
        const options = {
            method: httpMethods.POST,
            body: JSON.stringify(details),
            headers: { 'Content-Type': 'application/json' },
        };

        trigger('/api/users/edit-profile', options);
    }

    function handleSave() {
        if (done && !error) {
            const { hasError = true, data = {} } = result ?? {};
            if (!hasError) {
                setUserDetails(data);
            }
        }
    }

    useEffect(handleSave, [done]);

    const hasError = done && (error || (result?.hasError ?? true));
    const errorMessage = (result?.errors || ['Ett fel uppstod']).join(', ');

    return (
        <>
            <AuthenticatedTopMenu>
                <HeaderMessage text="Kontoinställningar" />
            </AuthenticatedTopMenu>
            <div className="p-4">
                <TextField
                    type="text"
                    name="givenName"
                    value={details.givenName}
                    onChange={editDetails}
                    label="Förnamn"
                    placeholder="Anna"
                />
                <div className="my-4" />
                <TextField
                    type="text"
                    name="surname"
                    value={details.surname}
                    onChange={editDetails}
                    label="Efternamn"
                    placeholder="Annasson"
                />
                <div className="my-4" />
                <TextField
                    type="tel"
                    name="phoneNumber"
                    value={details.phoneNumber}
                    onChange={editDetails}
                    label="Telefonnummer"
                    placeholder="073 123 56 70"
                />
                <div className="my-4" />
                <TextField
                    type="email"
                    name="email"
                    value={details.email}
                    onChange={editDetails}
                    label="E-post"
                    placeholder="din-mail@gmail.com"
                />
                <div className="my-4" />
                <TextField
                    type="text"
                    value={details.preferredStoreIdentifier}
                    onChange={editPreferredStoreIdentifier}
                    label="Butik-ID"
                    placeholder="4 tecken"
                />
                <div className="text-center mt-8">
                    <GreenButton onClick={doUpdate} disabled={sending}>{sending ? 'Sparar ...' : 'Spara'}</GreenButton>
                    {hasError && (
                        <small className="block my-4 text-red-600">{errorMessage}</small>
                    )}
                    {done && !hasError && (
                        <small className="block my-4 text-boxmeal-green-600">Sparat!</small>
                    )}
                    <div className="my-4" />
                    <LightButton onClick={() => history.push(allRoutes.account)}>Gå tillbaka</LightButton>
                </div>
            </div>
        </>
    );
}
