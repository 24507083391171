import React from 'react';
import { Menu } from '@headlessui/react';
import Preview from '../Notifications/Preview';
import { notificationStatus } from '../../utils/enums';
import { authenticatedHttpQuery, httpMethods } from '../../utils/http';
import useModal from '../../hooks/useModal';

export default function Notification({
    id,
    status,
    message,
    imageUrl,
}) {
    const { openModal } = useModal();

    function openNotification() {
        if (id) {
            if (status !== notificationStatus.clicked.value) {
                authenticatedHttpQuery({ url: `/api/notifications/clicked/${id}`, options: { method: httpMethods.POST } });
            }

            openModal({
                title: `Notis #${id}`,
                component: 'notification',
                componentProps: { imageUrl, message },
            });
        }
    }

    return (
        <Menu.Item key={`notification-${id}`}>
            <div role="button" tabIndex={-1} onClick={openNotification}>
                <Menu.Button className="w-full text-left">
                    <Preview message={message} status={status} />
                </Menu.Button>
            </div>
        </Menu.Item>
    );
}
