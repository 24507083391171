import { useRef, useEffect } from 'react';

function deepEqual(x, y) {
    return (x && y && typeof x === 'object' && typeof y === 'object')
        ? (Object.keys(x).length === Object.keys(y).length)
        && Object.keys(x).reduce((isEqual, key) => isEqual && deepEqual(x[key], y[key]), true) : (x === y);
}

function useDeepCompareMemoize(value) {
    const ref = useRef();

    if (!deepEqual(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

export default function useDeepEffect(callback, dependencies) {
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
