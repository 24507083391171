export const notificationStatus = {
    unseen: { value: 0, description: 'Osedd' },
    seen: { value: 1, description: 'Sedd' },
    clicked: { value: 2, description: 'Klickad' },
};

export const orderStatus = {
    open: { value: 0, description: 'Öppen' },
    pending: { value: 10, description: 'Väntar' },
    paid: { value: 20, description: 'Betalad' },
    cancelled: { value: 99, description: 'Avbruten' },
};

export const categories = {
    food: { value: 'Mat', description: 'Mat' },
};
